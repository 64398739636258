// src/components/NavBar.js

import './Navbar.css';

import React from "react";
import { useAuth0 } from "./react-auth0-wrapper";

import packageDetails from "../../../package.json";

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  let theLogButton = null;

  if(isAuthenticated){
    theLogButton = (<button className="Navbar-profile" onClick={() => logout()}>Log out</button>);
  }
  else{
    theLogButton = (<button  className="Navbar-profile" onClick={() => loginWithRedirect({})}>Log in</button>);
  }

  // <h2 className="Navbar-tagLine">web development test site</h2>
  // <h1 className="Navbar-title">brianhiggins.net</h1>        <h1 className="Navbar-title">brianhiggins.net</h1>
  return (
    <div className="Navbar">
      <div className="Navbar-container">


        <h5 className="Navbar-version">Version: {packageDetails.version}</h5>
        { theLogButton }
      </div>
    </div>
  );
};

export default NavBar;
