import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import { appReducer } from './reducers/reducer.js'

import myPackage from "../../package.json"

import * as Debug from 'debug';
const debug = Debug("BH Store");


let initialState = {
  app: {
    page : "home",
    name : myPackage.name,
    version: myPackage.version
  }
};

let store = null;

export function getStore(){
  if(store === null){
    debug("creating store");
    store = createStore(combineReducers({app: appReducer, }), initialState, applyMiddleware(thunk));
  }
  return store;
}
