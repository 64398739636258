// src/App.js

import React from "react";
import MyAppBar from "./components/Appbar/MyAppbar";
import './App.css';

// New - import the React Router components, and the Profile page component
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/Pages/Home";
//import Profile from "./components/Pages/Profile";
import Contact from "./components/Pages/Contact";
import About from "./components/Pages/About";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { connect }  from 'react-redux';
// import {connect_action} from '../../state/actions/a_connect';
import {appReducer} from './state/reducers/reducer';

import * as Debug from 'debug';
const debug = Debug("BH App");


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(1)
  },
}));

const mapStateToProps = state => {
  debug('mapStateToProps %O', state);
  return {  app: state.app,  };
}

function App() {
  const classes = useStyles();

  return (
    <div className="App">
      {/* New - use BrowserRouter to provide access to /profile */}
      <BrowserRouter>
        <MyAppBar />
        <Paper className={classes.root}>
          <Switch>
            <Route path="/" exact component={Home}/>
            <Route path="/contact" component={Contact} />
            <Route path="/about" component={About} />
          </Switch>
        </Paper>
      </BrowserRouter>
    </div>
  );
}

export default connect(mapStateToProps, { appReducer })(App);
