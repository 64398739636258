import * as Debug from 'debug';
const debug = Debug("BH Reducer");

export function appReducer(state, action) {

  if(state === undefined){
    state = {};
  }
  else if(action.type === "LOGON")
  {
    debug('action.type:' + action.type);
    debug("State before");
    debug(state);

    state = Object.assign({}, state, { connecting:true, failed: false, author : action.payload });

    debug("State after");
    debug(state);
  }
  else if(action.type === "LOGON_SUCCESS")
  {
    debug('action.type:' + action.type);
    debug("State before");
    debug(state);

    state = Object.assign({}, state, {
        connecting:false,
        connected: true,
        failed: false
        //token:action.payload.token
    });



    debug("State after");
    debug(state);

    debug("Pass Token");

  }
  else if(action.type === "LOGON_FAILURE")
  {
    debug('action.type:' + action.type + ' action.payload:' + action.payload);

    state = Object.assign({}, state, {
        authenticating:false,
        authenticated: false,
        failed: true,
    });
  }

  else {
    debug('action.type:' + action.type);
    debug('payload');
    debug(action.payload);
    debug(state);
  }

  return state;
}
