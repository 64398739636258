import React, { Fragment } from "react";
import { useAuth0 } from "../Appbar/react-auth0-wrapper";

const Home = () => {

  return (
    <Fragment>

    <div>
      This is the home page
    </div>
    <img width="25px" src="logo.svg" alt="logo"/>
    </Fragment>
  );
};

export default Home;
