// src/components/Profile.js

import React, { Fragment } from "react";
import { useAuth0 } from "../Appbar/react-auth0-wrapper";

const Contact = () => {
  const { loading, user } = useAuth0();

  if (loading || !user) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <Fragment>
    <div>
    Contact details go here
    </div>
    </Fragment>
  );
};

export default Contact;
