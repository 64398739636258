import './Menubar.css';

import React from "react";
import { useAuth0 } from "./react-auth0-wrapper";

import { Link } from "react-router-dom";


const MenuBar = () => {
  const { isAuthenticated } = useAuth0();

    let theHomeLink = null;
    let theContactLink = null;
    let theAboutLink = null;

    theHomeLink = (<Link className="Menubar-link" to="/">Home</Link>);
    theContactLink = (<Link className="Menubar-link" to="/contact">Contact</Link>);
    theAboutLink = (<Link className="Menubar-link" to="/about">About</Link>);

  if(isAuthenticated){
    // add your extra authenticated links here
  }

  return (
    <div className="Menubar">
      <div className="Menubar-container">
        { theHomeLink }
        { theContactLink }
        { theAboutLink }
      </div>
    </div>
  );
};

export default MenuBar;
