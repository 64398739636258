// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./components/Appbar/react-auth0-wrapper";
import config from "./auth_config.json";
import './index.css'
import { Provider } from 'react-redux';
import { getStore } from "./state/store";

import myPackage from "../package.json"

import * as Debug from 'debug';
const debug = Debug("BH Index");

debug("Starting %O %O ", myPackage.name, myPackage.version );

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};


ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}>
    <Provider store = {getStore()}>
      <App/>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
