import './MyAppbar.css';

import React from "react";
import { useAuth0 } from "./react-auth0-wrapper";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';

import NavBar from "./Navbar";
import MenuBar from "./Menubar";

import { connect }  from 'react-redux';
// import {connect_action} from '../../state/actions/a_connect';
import {appReducer} from '../../state/reducers/reducer';

import * as Debug from 'debug';
const debug = Debug("BH MyAppBar");

const mapStateToProps = state => {
  debug('mapStateToProps %O', state);
  return {  app: state.app,  };
}



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


const MyAppBar = (props) => {
  const classes = useStyles();

  const { isAuthenticated,loginWithRedirect, logout } = useAuth0();

  let theMenubar = null;
  let theLoginStatus = null;

  if(isAuthenticated){

    theMenubar = (<MenuBar/>);

    theLoginStatus = (<AccountCircle />)
  }
  else {
    theLoginStatus = (<Button color="inherit" onClick={() => loginWithRedirect({})}>Login</Button>)

  }

  let path = null;
  path = window.location.pathname === "/" ? "home" : window.location.pathname;

  return (
    <div className="Appbar">
      <div className="Appbar-container">
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              { props.app.page }
            </Typography>
            <Typography variant="h6" className={classes.title}>
              { props.app.name} { props.app.version }
            </Typography>

            {theLoginStatus}
          </Toolbar>
        </AppBar>
        <NavBar/>
        { theMenubar }
      </div>
    </div>
  );
};

export default connect(mapStateToProps, { appReducer })(MyAppBar);
